import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import Inckd from 'assets/icons/inckd-icon.svg'

const TextBox = ({ title, content }: { title: string; content: string }) => {
  const { palette } = useTheme()

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Box sx={{ width: '14px', position: 'relative', bottom: '0.4rem' }}>
          <Inckd />
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: palette.common.white,
            marginBottom: '1rem',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          color: palette.common.white,
        }}
      >
        {content}
      </Typography>
    </Box>
  )
}

interface TextBoxesProps {
  title: string
  headline: string
  elements: { title: string; content: string }[]
}

const TextBoxes = ({ title, headline, elements }: TextBoxesProps) => {
  const { palette } = useTheme()
  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: '4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: 'calc(100vh - 8rem)',
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: palette.common.white,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          color: '#FAF3FE',
          maxWidth: '30rem',
          marginBottom: '6.5rem',
          marginTop: '1.5rem',
        }}
      >
        {headline}
      </Typography>
      <Grid container spacing={12}>
        {elements.map((el, idx) => (
          <Grid item xs={4} key={idx}>
            <TextBox title={el.title} content={el.content} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default TextBoxes
