import { Box, keyframes, Typography } from '@mui/material'
import Arrow from 'assets/icons/arrow-down.svg'
import ArrowUp from 'assets/icons/arrow-up-dark.svg'
import { Footer } from 'components/layouts/common'
import SearchNav from 'components/layouts/common/Navbar/SearchNav'
import { FOOTER_NAV_LINKS } from 'constants/navigation'
import Link from 'next/link'
import { Dispatch, ReactElement, SetStateAction } from 'react'

const move = keyframes`
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }

`

const renderButton = (type: 'up' | 'down', scrollTo: string) => {
  if (type === 'up') {
    return (
      <Box
        sx={{
          animation: `${move} 0.4s infinite ease alternate`,
          cursor: 'pointer',
          position: 'absolute',
          bottom: '240px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Link href={`#${scrollTo}`}>
          <ArrowUp />
        </Link>
      </Box>
    )
  } else if (type === 'down') {
    return (
      <Box
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          bottom: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Link href={`#${scrollTo}`}>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Typography variant="subtitle2" sx={{ color: ' white' }}>
              See how it works
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                animation: `${move} 0.4s infinite ease alternate`,
              }}
            >
              <Arrow />
            </Box>
          </Box>
        </Link>
      </Box>
    )
  }
}

interface ScrollElementProps {
  children: ReactElement
  idx: number
  scrollTo?: string
  button?: 'up' | 'down'
  isFirst: boolean
  isLast: boolean
  onSearchResults: Dispatch<SetStateAction<string[]>>
}

const ScrollElement = (props: ScrollElementProps) => {
  const { children, idx, scrollTo, button, onSearchResults, isFirst, isLast } =
    props

  return (
    <div
      style={{
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
        scrollSnapPointsY: '10px',
        height: isLast ? '130vh' : '100vh',
        width: '100%',
        position: 'relative',
      }}
      key={`section-${idx}`}
    >
      {isFirst && (
        <SearchNav onSearchResults={(results) => onSearchResults(results)} />
      )}
      {children}
      {button && scrollTo && renderButton(button, scrollTo)}
      {isLast && <Footer navLinks={FOOTER_NAV_LINKS} />}
    </div>
  )
}

export default ScrollElement
