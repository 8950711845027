import { Box, Button, Grid, Typography } from '@mui/material'
import ArtistsImage from 'assets/images/homepage/home-artist.webp'
import NextImage from 'next/image'
import { useRouter } from 'next/router'

const SectionArtists = () => {
  const router = useRouter()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        height: '100vh',
        width: '100%',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '7.5rem',
            position: 'relative',
          }}
        >
          <NextImage src={ArtistsImage} layout="fill" objectFit="cover" />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '7.5rem',
          }}
        >
          <Box
            sx={{
              maxWidth: '420px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                color: ' white',
                fontSize: '3.125rem',
                fontWeight: '700',
              }}
            >
              Search for artists
            </Typography>
            <Typography
              sx={{
                color: ' white',
                fontSize: '1.125rem',
                fontWeight: '400',
                mb: '1rem',
              }}
            >
              Get inspired with the world of tattoos. You can follow the artists
              and browse their work. Save the tattoos for later or share them
              with friends!
            </Typography>
            <Button
              variant="filled-rounded"
              color="white"
              sx={{
                width: 'fit-content',
                padding: '0.68rem 2.94rem 0.56rem',
                color: '#331c62',
              }}
              onClick={() =>
                router.push('/search?searchPhrase=&category=artists')
              }
            >
              Explore
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SectionArtists
