import Box from '@mui/material/Box'
import ScrollElement from 'components/layouts/ScrollLayout/ScrollElement'
import React, { ReactElement } from 'react'
import { createContext, useState } from 'react'

export const SearchResultsContext = createContext<string[]>([''])

const ScrollLayout = ({
  components,
  background,
}: {
  components: {
    component: ReactElement
    button?: 'up' | 'down'
    scrollTo?: string
  }[]
  background: string
}) => {
  const [results, setResults] = useState<string[]>([])

  return (
    <SearchResultsContext.Provider value={results}>
      <Box>
        <Box
          sx={{
            background: background,
            position: 'absolute',
            zIndex: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
        />

        {components.map((el, idx) => {
          return (
            <ScrollElement
              key={idx}
              idx={idx}
              scrollTo={el.scrollTo}
              button={el.button}
              onSearchResults={setResults}
              isFirst={idx === 0}
              isLast={idx === components.length - 1}
            >
              {el.component}
            </ScrollElement>
          )
        })}
      </Box>
    </SearchResultsContext.Provider>
  )
}

export default ScrollLayout
