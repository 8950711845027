import { Box, Button, Container, Grid, Typography } from '@mui/material'
import TattoosImage from 'assets/images/tattoos.webp'
import NextImage from 'next/image'
import { useRouter } from 'next/router'

const SectionTattoos = () => {
  const router = useRouter()
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100vh',
        justifyContent: 'center',
      }}
      id="section-tattoos"
    >
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: '7.5rem',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                color: ' white',
                fontSize: '3.125rem',
                fontWeight: '700',
              }}
            >
              Search for tattoos & flash
            </Typography>
            <Typography
              sx={{
                color: ' white',
                fontSize: '1.125rem',
                fontWeight: '400',
                mb: '1rem',
              }}
            >
              Get inspired with the world of tattoos and flashes. You can follow
              the artists and browse their work. Save the tattoos for later or
              share them with friends!
            </Typography>

            <Button
              variant="filled-rounded"
              color="white"
              sx={{
                width: 'fit-content',
                padding: '0.68rem 2.94rem 0.56rem',
                color: '#491b7e',
              }}
              onClick={() => router.push('/search?searchPhrase=&category=all')}
            >
              Explore
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '7.5rem',
            position: 'relative',
            height: '100vh',
          }}
        >
          <NextImage src={TattoosImage} layout="fill" objectFit="contain" />
        </Grid>
      </Grid>
    </Container>
  )
}

export default SectionTattoos
