import { Box, Container, Typography, useTheme } from '@mui/material'
import NextImage from 'next/image'

const MobileElement = ({
  title,
  image,
}: {
  title: string
  image: StaticImageData
}) => {
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        marginBottom: '4rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '3.125rem',
      }}
    >
      <Box sx={{ width: '240px', height: '400px', position: 'relative' }}>
        <NextImage src={image} layout="fill" />
      </Box>
      <Typography variant="subtitle1" sx={{ color: palette.common.white }}>
        {title}
      </Typography>
    </Box>
  )
}

interface MobilePresentationProps {
  title: string
  headline: string
  elements: { title: string; image: StaticImageData }[]
}

const MobilePresentation = ({
  title,
  headline,
  elements,
}: MobilePresentationProps) => {
  const { palette } = useTheme()
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        maxHeight: 'calc(100vh)',
        alignItems: 'center',
        padding: '2rem 0',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: palette.common.white,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: palette.common.white,
            maxWidth: '30rem',
            marginTop: '1.5rem',
          }}
        >
          {headline}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'spaceBetween',
        }}
      >
        {elements.map((el, idx) => (
          <MobileElement
            key={`${el.title}-${idx}`}
            title={el.title}
            image={el.image}
          />
        ))}
      </Box>
    </Container>
  )
}

export default MobilePresentation
