import { Box, Typography } from '@mui/material'
import MobileFrame from 'assets/images/homepage/device.webp'
import { AppStoreLogo } from 'components/icon'
import useHeroStyles from 'components/pages/home/Hero/Hero.styles'
import NextImage from 'next/image'

const Hero = () => {
  const styles = useHeroStyles()
  return (
    <Box sx={styles.container} id="section-hero">
      <Box>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'relative' }}>
            <NextImage src={MobileFrame} priority />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <video
                src={require('assets/images/home-animation/final.mp4')}
                muted
                autoPlay
                loop
                style={{ borderRadius: '0px', maxWidth: '90%' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ maxWidth: '50%' }}>
        <Typography variant="h6" sx={{ fontWeight: 700, color: ' white' }}>
          Meet the #1 app
        </Typography>
        <Typography variant="h3" sx={{ color: ' white' }}>
          For tattoo artists & lovers world-wide
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '24px',
            '.bg': {
              fill: 'rgba(255,255,255, 0.2)',
            },
          }}
        >
          <AppStoreLogo store="apple" transparent />
          <AppStoreLogo store="google" transparent />
        </Box>
      </Box>
    </Box>
  )
}

export default Hero
