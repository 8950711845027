import Box from '@mui/material/Box'
import Iphone1 from 'assets/images/homepage/iphone-home1.webp'
import Iphone2 from 'assets/images/homepage/iphone-home2.webp'
import Iphone3 from 'assets/images/homepage/iphone-home3.webp'
import Iphone4 from 'assets/images/homepage/iphone-home4.webp'
import MobilePresentation from 'components/layouts/common/MobilePresentation/MobilePresentation'
import TextBoxes from 'components/layouts/common/TextBoxes/TextBoxes'
import ScrollLayout from 'components/layouts/ScrollLayout/ScrollLayout'
import Hero from 'components/pages/home/Hero/Hero'
import SectionArtists from 'components/pages/home/SectionArtists/SectionArtists'
import SectionTattoos from 'components/pages/home/SectionTattoos/SectionTattoos'
import type { NextPage } from 'next'
import Head from 'next/head'

const textElements = [
  {
    title: 'Get inspired',
    content:
      'make use of organized base of styles and tattoos that will allow you to get inspired easily.',
  },
  {
    title: 'Save your time',
    content:
      'use support in every step of the process, from finding inspiration to contacting the best artist and getting the tattoo done. Smooth & fast.',
  },
  {
    title: 'Feel safe',
    content:
      'agree on a clear price and usage of payment method that suits you best. All tattoo artists on the platform are verified.',
  },
]

const mobilePresentationElements = [
  { title: 'Discover styles', image: Iphone1 },
  { title: 'Find your artist', image: Iphone2 },
  { title: 'Get in touch', image: Iphone3 },
  { title: 'Pay safely in-app', image: Iphone4 },
]

const Search: NextPage = () => {
  return (
    <Box id="elements">
      <Head>
        <title>inckd.</title>
        <meta
          name="description"
          content="A place where artists and tattoo lovers meet"
        />
        <link rel="canonical" href="https://inckd.com" />
      </Head>
      <ScrollLayout
        background="linear-gradient(0deg, #8C62DB 8.29%, #66119F 83.28%)"
        components={[
          {
            component: <Hero key="1" />,
            scrollTo: 'section-tattoos',
            button: 'down',
          },
          {
            component: <SectionTattoos key="2" />,
          },
          {
            component: <SectionArtists key="3" />,
          },
          {
            component: (
              <TextBoxes
                key="4"
                title={'Express yourself'}
                headline={'Creatively. Quickly. Safely. '}
                elements={textElements}
              />
            ),
          },
          {
            component: (
              <MobilePresentation
                title="Discover features"
                headline="Dare to change"
                elements={mobilePresentationElements}
                key="5"
              />
            ),
            scrollTo: 'section-hero',
            button: 'up',
          },
        ]}
      />
    </Box>
  )
}

export const getStaticProps = async () => {
  return {
    props: {
      withScroll: true,
    },
  }
}

export default Search
