import { keyframes } from '@mui/system'

const move = keyframes`
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateY(4px);
  }

`

const useHeroStyles = () => {
  return {
    container: {
      height: 'calc(100vh - 8rem)',
      //   gap: '8.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 11rem',
    },

    cta: {
      display: 'flex',
      gap: '0.75rem',

      alignItems: 'center',
      cursor: 'pointer',
    },
    arrow: {
      display: 'flex',
      alignItems: 'center',
      animation: `${move} 0.4s infinite ease alternate`,
    },
  }
}

export default useHeroStyles
